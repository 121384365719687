import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens, { FontVariantToken } from '@uipath/apollo-core';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

import { UiBackgroundSignup } from '../../../../common/UiBackground/UiBackgroundSignup';
import { LeftColor } from '../../../../common/UiBackground/UiBackgroundV3';
import UiList from '../../../../common/UiList/UiList';
import palette from '../../../../theme/palette';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        title: {
            color: palette.light.title.primary,
            width: '100%',
            flexShrink: 0,
            fontSize: '32px',
            lineHeight: '32px',
            fontWeight: 500,
            fontFamily: 'Poppins',
            marginBottom: '38px',
        },
        titleName: { color: palette.light.title.tertiary },
        section: {
            marginTop: '32px',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            flexShrink: 0,
        },
        listHeader: {
            color: palette.light.list.header,
            marginBottom: '16px',
            fontWeight: 700,
        },
        header: {
            color: palette.light.subtitle,
            fontSize: '14px',
            fontWeight: 600,
        },
        list: {
            marginTop: '24px',
            width: '100%',
            flexShrink: 0,
        },
        trademark: {
            fontWeight: 400,
            fontSize: '8px',
            position: 'relative',
            top: '-2.5em',
        },
        proTrialLink: {
            fontWeight: 600,
            zIndex: 1,
        },
        linkUnderline: {
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' },
        },
        headerExp: {
            fontFamily: Tokens.FontFamily.FontHeader2BoldFamily,
            fontWeight: Tokens.FontFamily.FontWeightDefault,
            fontSize: Tokens.FontFamily.FontBrandLSize,
            lineHeight: '28px',
        },
    }),
}));

const TestCloudForEnterprise = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const items = useMemo(() =>
        [
            [
                'PRO_TRIAL_ROBOTS_ENABLE',
                'SUPPORT_24_7',
                'UPTIME_GUARANTEE',
                'MULTIPLE_TENANTS',
            ],
            [],
        ]
    , [ ]);

    const testCloud = 'Test Cloud';

    return (
        <UiBackgroundSignup
            isExperiment={false}
            color={LeftColor.Blue}
            showTitle
            title={testCloud}>
            <UiText
                className={classes.title}
                data-cy="test-cloud-trial-title">
                <FormattedMessage
                    id="TESTCLOUD_ENTERPRISE_HEADER"
                    description="Welcome Message"
                    values={{
                        title: (chunks: React.ReactNode[]) => (
                            <span className={classes.titleName}>
                                {chunks}
                            </span>
                        ),
                        tm: (chunks: React.ReactNode[]) => <span className={classes.trademark}>
                            {chunks}
                        </span>,
                    }}
                />
            </UiText>
            <div
                className={classes.list}
                data-cy="test-cloud-list">
                <UiText
                    role="heading"
                    aria-level={6}
                    variant={FontVariantToken.fontSizeMBold}
                    className={classes.listHeader}>
                    {translate({ id: 'TESTCLOUD_FULL_CAPABILITIES_OFFER_PRO' })}
                </UiText>
                <UiList
                    items={items[0]}
                    color={palette.light.list.body}
                    colorListItemCheckmark="green" />
            </div>
            <div>
                <div
                    className={classes.list}
                    data-cy="test-cloud-trial-div">
                    <UiText className={classes.proTrialLink}>
                        <FormattedMessage
                            id="PRO_TRIAL_OLD_SIGN_UP_PLAN"
                            values={{
                                a: (msg: any) =>
                                    (
                                        <a
                                            className={clsx(classes.a, classes.linkUnderline)}
                                            href="https://www.uipath.com/pricing#comparison"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {msg}
                                        </a>
                                    ) as any,
                            }}
                        />
                    </UiText>
                </div>
            </div>
        </UiBackgroundSignup>
    );
};

export default TestCloudForEnterprise;
