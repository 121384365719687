import type { PropsWithChildren } from 'react';
import React from 'react';

import palette from '../../theme/palette';
import { UiBackgroundV2 } from './UiBackgroundV2';
import type { LeftColor } from './UiBackgroundV3';
import { UiBackgroundV3 } from './UiBackgroundV3';

export const UiBackgroundSignup = (props: PropsWithChildren<{
    isExperiment: boolean;
    color: LeftColor;
    showTitle?: boolean;
    title?: string;
}>) => props.isExperiment ?
    <UiBackgroundV3
        {...props} /> :
    <UiBackgroundV2
        shapeColor={palette.light.shape}
        {...props} />;
