import React from 'react';

import { useParams } from '../../../container/Router';
import AutomationCloudForCommunity from './content/AutomationCloudForCommunity';
import AutomationCloudForEnterprise from './content/AutomationCloudForEnterprise';
import AutomationCloudFree from './content/AutomationCloudFree';
import DefaultContent from './content/DefaultContent';
import TestCloudForEnterprise from './content/TestCloudForEnterprise';

const LeftContainer = () => {
    const params = useParams();
    switch (params.platform_name) {
        case 'UiPath Platform':
        case 'UiPath Automation Cloud':
        case 'UiPath Automation Cloud for community':
            return <AutomationCloudForCommunity />;
        case 'UiPath Automation Cloud Free':
            return <AutomationCloudFree />;
        case 'UiPath Automation Cloud for enterprise':
        case 'UiPath Automation Hub':
        case 'Automation Hub':
            return <AutomationCloudForEnterprise />;
        case 'UiPath Automation Cloud for enterprise by BDR source':
            return <AutomationCloudForEnterprise source="BDR" />;
        case 'UiPath Test Cloud':
            return <TestCloudForEnterprise />;
        default:
            return <DefaultContent />;
    }
};

export default LeftContainer ;
